<template>
  <div
    class="card card-custom"
    :class="
      !showInModal ? 'offset-md-0 col-md-12 offset-lg-1 col-lg-10 col-xl-8' : ''
    "
  >
    <div class="card-body position-relative">
      <div class="text-right">
        <button class="btn btn-secondary mr-2" @click="cancel">
          {{ $t("dataStructures.cancelCreate") }}
        </button>
        <button
          v-if="option !== 2 || showInModal"
          class="btn btn-primary"
          :disabled="!valid"
          @click="saveDataStructure"
        >
          {{ $t("general.next") }}
        </button>
        <button
          v-else-if="stepBasics"
          class="btn btn-primary"
          :disabled="!valid"
          @click="startWizard"
        >
          {{ $t("dataStructures.startWizard") }}
        </button>
      </div>

      <StepBasics
        v-if="stepBasics"
        :configuration="configuration"
        :show-data-structure-options="!showInModal"
        @data-structure-changed="dataStructureChanged"
      />

      <div
        v-show="!stepBasics"
        id="wizard_data_structure"
        class="wizard wizard-1"
        data-wizard-state="step-content"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-file"></i>
                <h3 class="wizard-title">
                  {{ $t("dynamicModels.stepAnalyze") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-sliders"></i>
                <h3 class="wizard-title">
                  {{ $t("dataStructures.stepConfig") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-rocket-launch"></i>
                <h3 class="wizard-title">{{ $t("dynamicModels.stepSave") }}</h3>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 mb-lg-15 px-lg-10">
          <div class="col">
            <div data-wizard-type="step-content">
              <StepAnalyze
                ref="step-analyze"
                :key="componentKey"
                :data="data"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepBasicConfiguration
                :key="componentKey"
                ref="step-basic-configuration"
                :analyzed-data="dataStructure"
                @analyze="reanalyzeFile"
              />
            </div>
            <div>
              <StepSave
                :key="componentKey"
                ref="step-save"
                :data-structure="dataStructure"
              />
            </div>
          </div>
        </div>
        <!--begin: Wizard Actions -->
        <div
          class="d-flex justify-content-between border-top pt-10 wizard-actions"
        >
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-prev"
              :disabled="isBusy"
            >
              {{ $t("dynamicModels.previous") }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
              :disabled="isBusy || !isValid"
              @click="saveDataStructure"
            >
              {{ $t("dynamicModels.goToEditor") }}
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-next"
              :disabled="data.fileType === null || isBusy"
              @click.prevent="nextStep"
            >
              <span v-if="!isStepAnalyze">{{ $t("dynamicModels.next") }}</span>
              <span v-else>
                <i class="fal fa-magnifying-glass" />
                {{ $t("dynamicModels.analyze") }}
              </span>
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </div>
    </div>
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";

import StepBasics from "./Components/StepBasics";

import StepAnalyze from "./Components/StepAnalyze";
import StepBasicConfiguration from "./Components/StepBasicConfiguration";
import StepSave from "./Components/StepSave";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_DATA_STRUCTURE_CREATED } from "@/core/services/store/dataStructure.module";

import { cloneDeep, generateHash } from "@/components/Tools/helperFunctions";

let DataStructureService;

export default {
  components: {
    StepBasics,
    StepAnalyze,
    StepBasicConfiguration,
    StepSave
  },
  props: {
    configuration: Object,
    showInModal: {
      type: Boolean,
      default: false
    },
    jsonData: String
  },
  data() {
    return {
      breadcrumbs: {
        0: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/project/data-structures"
          },
          {
            title: this.$t("dataStructures.create"),
            route: "/project/data-structures/create"
          }
        ],
        2: [
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/partner/data-structures"
          },
          {
            title: this.$t("dataStructures.create"),
            route: "/partner/data-structures/create"
          }
        ],
        3: [
          {
            title: this.$t("menu.adminSettings"),
            route: ""
          },
          {
            title: this.$t("dataStructures.dataStructures"),
            route: "/admin/settings/data-structures"
          },
          {
            title: this.$t("dataStructures.create"),
            route: "/admin/settings/data-structures/create"
          }
        ]
      },
      stepBasics: true,
      option: 0,
      valid: false,
      dataStructureFieldTypes: [],
      dataStructure: {
        config: {}
      },

      wizard: null,
      data: {
        mode: 0,
        file: null,
        text: "",
        fileType: null,
        config: {}
      },
      analyzedData: {},
      componentKey: 1,
      isBusy: false,
      isValid: true
    };
  },
  computed: {
    isStepAnalyze: function () {
      if (!this.wizard) return false;
      return this.wizard.getStep() === 1;
    },
    isStepBasicConfig: function () {
      if (!this.wizard) return false;
      return this.wizard.getStep() === 2;
    }
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;

    if (!this.showInModal) {
      this.setBreadcrumbs();
    }

    this.loadFieldTypes();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(
        SET_BREADCRUMB,
        this.breadcrumbs[this.configuration.area]
      );
    },
    loadFieldTypes() {
      DataStructureService.getFieldTypes()
        .then(response => {
          this.dataStructureFieldTypes = [
            ...response.data.data.data_fields,
            ...response.data.data.structure_fields
          ];
        })
        .finally(() => {
          if (this.showInModal) {
            let formData = new FormData();
            formData.append("string", this.jsonData);
            formData.append("type", 2);
            this.analyzeFile(formData);
          }
        });
    },
    dataStructureChanged(option, valid, dataStructure) {
      this.option = option;
      this.valid = valid;
      this.dataStructure = dataStructure;
      if (this.dataStructure.config === undefined) {
        this.dataStructure.config = {};
      }
      if (this.showInModal && this.analyzedData) {
        this.setDataStructureData();
      }
    },
    startWizard() {
      this.stepBasics = false;
      this.initializeWizard();
    },
    initializeWizard() {
      this.wizard = new KTWizard("wizard_data_structure", {
        clickableSteps: false
      });
    },
    nextStep() {
      if (this.isStepAnalyze) {
        this.wizard.stop();
        this.analyzeFile(this.getFormData());
      } else if (this.isStepBasicConfig) {
        this.wizard.stop();
        this.data.config = this.$refs["step-basic-configuration"].getConfig();
        this.analyzedData.config = this.data.config;
        this.componentKey++;
        this.wizard.resume();
      }
    },
    analyzeFile(data) {
      this.isBusy = true;
      if (!this.showInModal) {
        let stepConfig = this.$refs["step-analyze"].getConfig();
        Object.keys(stepConfig).forEach(key => {
          data.append("config[" + key + "]", stepConfig[key]);
        });
      }
      data.append(
        "config[onEmptyData]",
        this.dataStructure.onEmptyDataOption ?? ""
      );
      data.append(
        "config[checkStructure]",
        !!this.dataStructure.checkStructure
      );
      DataStructureService.analyze(data)
        .then(response => {
          if (response.status !== 200) {
            return this.$toast.fire({
              icon: "error",
              title: this.$t("error.error") + response.status
            });
          }
          this.analyzedData = response.data;
          if (this.analyzedData.fields.length > 0) {
            this.analyzedData.primary = this.analyzedData.fields[0].name;
          }
          this.isValid = this.analyzedData.fields.length > 0;
          this.setDataStructureData();
          this.isBusy = false;

          if (this.showInModal) {
            return;
          }

          this.componentKey++;
          this.wizard.goTo(2);
        })
        .catch(error => {
          console.log(error);
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    reanalyzeFile(config) {
      this.isBusy = true;
      this.data.config = config;
      const data = this.getFormData();
      DataStructureService.analyze(data)
        .then(response => {
          if (response.status !== 200) {
            return this.$toast.fire({
              icon: "error",
              title: this.$t("error.error") + response.status
            });
          }
          this.analyzedData.config = response.data.config;
          this.analyzedData.entries = response.data.entries;
          this.analyzedData.fields = response.data.fields;
          this.analyzedData.primary =
            response.data.primary ?? this.analyzedData.primary;
          // this.componentKey++;
          this.isBusy = false;
          this.isValid = this.analyzedData.fields.length > 0;
          if (this.analyzedData.fields.length > 0) {
            this.analyzedData.primary = this.analyzedData.fields[0].name;
          }
          this.setDataStructureData();
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getFormData() {
      let formData = new FormData();
      if (this.data.mode === 0) {
        formData.append("file", this.data.file);
      } else if (this.data.mode === 1) {
        formData.append("string", this.data.text);
      }
      formData.append("type", this.data.fileType);
      Object.keys(this.data.config).forEach(key => {
        formData.append("config[" + key + "]", this.data.config[key]);
      });
      return formData;
    },
    setDataStructureData() {
      this.dataStructure.fields = [];
      if (!this.analyzedData) return;

      this.dataStructure.primary = this.analyzedData.primary;
      this.dataStructure.config = this.analyzedData.config;
      this.dataStructure.config.type = this.analyzedData.type;
      this.dataStructure.entries = this.analyzedData.entries;
      this.analyzedData.fields.forEach(field => {
        field.id = generateHash();
        field.isLeaf = false;
        field.label = field.name;
        field.original_name = field.originalName;
        field.show_in_view = !field.parent && field.type !== "collection";
        field.description = "";
        field.config = [];
        field.parent_id = null;

        const fieldType = this.dataStructureFieldTypes.find(
          f => f.name === field.type
        );
        if (fieldType && fieldType.config) {
          field.config = cloneDeep(fieldType.config);
          field.config.forEach(conf => {
            conf.value = null;
          });
        }

        if (field.parent) {
          const parent = this.dataStructure.fields.find(
            f => f.fullName === field.parent
          );
          if (parent) {
            field.parent_id = parent.id;
          }
        }

        this.dataStructure.fields.push(field);
      });
    },
    saveDataStructure() {
      this.$store.dispatch(SET_DATA_STRUCTURE_CREATED, this.dataStructure);
      if (!this.showInModal) {
        this.$router.push({ name: this.configuration.editorRoute });
        return;
      }
      this.$emit("show-editor");
    },
    cancel() {
      if (this.showInModal) {
        this.$emit("cancel");
        return;
      }
      this.$router.push({ name: this.configuration.tableRoute });
    }
  }
};
</script>

<style lang="scss">
@import "../../../../../assets/sass/pages/wizard/wizard-1";

.wizard-actions {
  position: sticky;
  background: #fff;
  padding-bottom: 20px;
  bottom: 0;
}
</style>
