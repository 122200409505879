<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("dynamicModels.tablePreview", { rows: rows }) }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          responsive
          :items="items"
          :fields="tableFields"
          class="dataTable table table-head-custom csv-preview"
          bordered
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fields", "items", "rows"],
  data() {
    return {};
  },
  computed: {
    tableFields: function () {
      if (!this.fields) return [];
      let fields = [];
      this.fields.forEach((field, key) => {
        fields.push({ key: key.toString(), label: field.name });
      });
      return fields;
    }
  }
};
</script>

<style lang="scss">
.table.table-head-custom {
  &.csv-preview {
    thead {
      th {
        text-transform: none !important;
      }
    }
  }
}
</style>
