<template>
  <div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.encoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <v-select
            v-model="config.encoding"
            class="form-control"
            :items="encodingOptions"
            item-text="item"
            item-value="item"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dynamicModels.placeholderSelect')"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-code icon" />
            </span>
          </div>
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.emptyForAutoDetection") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.previewLines") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <input
            v-model="config.previewLines"
            type="number"
            min="1"
            class="form-control"
          />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.defaultTen") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.maxDepth") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <input
            v-model="config.maxDepth"
            type="number"
            min="1"
            class="form-control"
          />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.iterationMaxDepth") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { encodingOptions } from "@/components/Tools/encodingOptions";

export default {
  props: ["initialConfig"],
  data() {
    return {
      config: {
        previewLines: 10,
        maxDepth: 3
      },
      encodingOptions: encodingOptions
    };
  },
  mounted() {
    this.mapInitialConfig();
  },
  methods: {
    mapInitialConfig() {
      this.config.type = this.initialConfig.type;
      this.config.previewLines = this.initialConfig.previewLines ?? 10;
      this.config.maxDepth = this.initialConfig.maxDepth ?? 3;
      this.config.encoding = this.initialConfig.encoding;
    },
    fetchConfig() {
      let config = {};
      config.type = this.config.type;
      if (this.config.previewLines)
        config.previewLines = this.config.previewLines;
      if (this.config.maxDepth) config.maxDepth = this.config.maxDepth;
      if (this.config.encoding) config.encoding = this.config.encoding;
      return config;
    }
  }
};
</script>

<style scoped></style>
