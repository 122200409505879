<template>
  <div data-wizard-type="step-content">
    <div v-if="dataStructure && dataStructure.config">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.selectedConfiguration") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.file") }}
        </label>
        <div class="col-lg-9 col-xl-9">
          <div class="input-group">
            <input
              v-model="dataStructure.filename"
              type="text"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.fileType") }}
        </label>
        <div class="col-lg-9 col-xl-9">
          <div class="input-group">
            <input
              type="text"
              :value="types[dataStructure.type]"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.primaryKey") }}
        </label>
        <div class="col-lg-9 col-xl-9">
          <div class="input-group">
            <v-combobox
              v-model="dataStructure.primary"
              :items="dataStructure.fields"
              item-value="name"
              item-text="name"
              class="form-control"
              :menu-props="{ offsetY: true }"
              @update:search-input="updateSearchInput"
            />
          </div>
        </div>
      </div>

      <div
        v-if="dataStructure.config.encoding"
        class="form-group row align-items-center"
      >
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.encoding") }}
        </label>
        <div class="col-lg-9 col-xl-9">
          <div class="input-group">
            <input
              v-model="dataStructure.config.encoding"
              type="text"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataStructure: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      types: ["CSV", "XML", "JSON"]
    };
  },
  methods: {
    updateSearchInput(data) {
      this.dataStructure.primary = data;
    }
  }
};
</script>
