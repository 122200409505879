<template>
  <div>
    <div v-if="Object.keys(analyzedData).length">
      <div class="row">
        <label class="col-3"></label>
        <div class="col-9">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.analyzeSettings") }}:
          </h5>
        </div>
      </div>
      <!-- START: Config forms -->
      <ConfigCSV
        v-if="analyzedData.config.type === 0"
        ref="step-basic-config-0"
        :initial-config="analyzedData.config"
      />
      <ConfigXML
        v-else-if="analyzedData.config.type === 1"
        ref="step-basic-config-1"
        :initial-config="analyzedData.config"
      />
      <ConfigJSON
        v-else-if="analyzedData.config.type === 2"
        ref="step-basic-config-2"
        :initial-config="analyzedData.config"
      />

      <div v-if="[0, 1, 2].includes(analyzedData.config.type)">
        <div class="form-group row align-items-center">
          <label class="col-3 col-form-label">
            {{ $t("dataStructures.onEmptyData") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="onEmptyDataOption"
                class="form-control"
                :items="onEmptyDataOptions"
                item-text="label"
                item-value="value"
                :menu-props="{ offsetY: true }"
              />
            </div>
            <span class="form-text text-muted">{{
              $t("dataStructures.onEmptyDataTooltip")
            }}</span>
          </div>
        </div>
      </div>

      <div v-if="[0, 1, 2].includes(analyzedData.config.type)">
        <div class="form-group row align-items-center">
          <label class="col-3 col-form-label">
            {{ $t("dataStructures.checkStructure") }}
          </label>
          <div class="col-9 d-flex align-center">
            <span class="switch switch-sm mr-1">
              <label>
                <input v-model="checkStructure" type="checkbox" />
                <span></span>
              </label>
            </span>
            <i
              v-b-tooltip.top.noninteractive="
                $t('dataStructures.checkStructureTooltip')
              "
              class="fal fa-question-circle"
            />
          </div>
        </div>
      </div>

      <!-- END: Config forms -->
      <div class="row">
        <label class="col-3"></label>
        <div class="col-9">
          <button
            v-b-tooltip.hover.bottom.v-dark
            class="btn btn-primary mt-1"
            :title="$t('dynamicModels.resetsTableConfig')"
            @click="analyzeFile"
          >
            {{ $t("dynamicModels.reanalyzeFile") }}
          </button>
        </div>
      </div>
      <div class="separator separator-dashed my-5"></div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="">{{ $t("dynamicModels.showPreview") }}</div>
        </label>
        <div class="col-md-9 col-lg-8 col-xl-9">
          <div class="d-flex justify-space-between align-items-center">
            <span class="switch">
              <label>
                <input v-model="showPreview" type="checkbox" />
                <span></span>
              </label>
            </span>
            <i
              v-b-tooltip.top.noninteractive="
                $t('dynamicModels.showPreviewHelp')
              "
              class="fal fa-question-circle ml-1"
            />
          </div>
        </div>
      </div>
      <div v-if="showPreview">
        <!-- START: Previews -->
        <PreviewCSV
          v-if="analyzedData.config.type === 0"
          :fields="analyzedData.fields"
          :items="analyzedData.entries"
          :rows="analyzedData.config.entryQuantity"
        />
        <PreviewXML
          v-else-if="analyzedData.config.type === 1"
          :items="analyzedData.entries"
          :primary="analyzedData.primary"
          @primary-changed="setPrimaryKey"
        />
        <PreviewJSON
          v-else-if="analyzedData.config.type === 2"
          :items="analyzedData.entries"
          :preview-lines="
            analyzedData.config.previewLines
              ? analyzedData.config.previewLines
              : 10
          "
        />
        <!-- END: Previews -->
      </div>
      <div
        v-if="!showPreview && analyzedData.config.type === 1"
        class="form-group row align-items-center"
      >
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="">{{ $t("dynamicModels.primaryKey") }}</div>
        </label>
        <div class="col-md-9 col-lg-8 col-xl-9">
          <div class="d-flex align-items-center">
            <div class="input-group mr-1">
              <input
                v-model="analyzedData.primary"
                type="text"
                class="form-control"
              />
            </div>
            <i
              v-b-tooltip.top.noninteractive="
                $t('dynamicModels.primaryXmlHelp')
              "
              class="fal fa-question-circle ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Config templates
import ConfigCSV from "@/components/Projects/DynamicModels/DynamicModel/Configs/CSV";
import ConfigXML from "@/components/Projects/DynamicModels/DynamicModel/Configs/XML";
import ConfigJSON from "@/components/Projects/DynamicModels/DynamicModel/Configs/JSON";
// Preview templates
import PreviewCSV from "@/components/Projects/DynamicModels/DynamicModel/Previews/CSV";
import PreviewXML from "@/components/Projects/DynamicModels/DynamicModel/Previews/XML";
import PreviewJSON from "@/components/Projects/DynamicModels/DynamicModel/Previews/JSON";

export default {
  components: {
    ConfigCSV,
    ConfigXML,
    ConfigJSON,
    PreviewCSV,
    PreviewXML,
    PreviewJSON
  },
  props: ["analyzedData"],
  data() {
    return {
      showPreview: false,
      checkStructure: false,
      onEmptyDataOption: {
        label: "-",
        value: ""
      },
      onEmptyDataOptions: [
        {
          label: "-",
          value: ""
        },
        {
          label: "Warnung",
          value: "warn"
        },
        {
          label: "Fehler",
          value: "error"
        }
      ]
    };
  },
  computed: {
    acceptFileTypes: function () {
      let types = this.validFileTypes;
      let acceptString = "";
      types.forEach(type => {
        let string = "." + type + ", ";
        acceptString = acceptString.concat(string);
      });
      acceptString = acceptString.slice(0, -2);
      return acceptString;
    }
  },
  watch: {
    onEmptyDataOption() {
      this.analyzedData.config.onEmptyData = this.onEmptyDataOption;
    },
    checkStructure() {
      this.analyzedData.config.checkStructure = this.checkStructure;
    }
  },
  methods: {
    onFileInput(file) {
      let filename = file.name;
      let extension = filename.split(".").pop();
      let extensionMode = null;
      switch (extension) {
        case "csv":
          extensionMode = 0;
          break;
        case "xml":
          extensionMode = 1;
          break;
      }
      this.fileType = extensionMode;
    },
    analyzeFile() {
      let config =
        this.$refs[
          "step-basic-config-" + this.analyzedData.config.type
        ].fetchConfig();
      this.$emit("analyze", config);
    },
    getConfig() {
      return this.$refs[
        "step-basic-config-" + this.analyzedData.config.type
      ].fetchConfig();
    },
    setPrimaryKey(primary) {
      this.$set(this.analyzedData, "primary", primary);
    }
  }
};
</script>
