<template>
  <div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.encoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="input-group">
          <v-select
            v-model="config.encoding"
            class="form-control"
            :items="encodingOptions"
            item-text="item"
            item-value="item"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dynamicModels.placeholderSelect')"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-code icon" />
            </span>
          </div>
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.emptyForAutoDetection") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.forceEncoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="d-flex justify-space-between align-items-center">
          <span class="switch">
            <label>
              <input v-model="config.forceEncoding" type="checkbox" />
              <span></span>
            </label>
          </span>
          <i
            v-b-tooltip.top.noninteractive="
              $t('dynamicModels.showForceEncodingHelp')
            "
            class="fal fa-question-circle ml-1"
          />
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.selector") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="d-flex align-items-center">
          <div class="input-group flex-grow-1 mr-1">
            <input v-model="config.selector" type="text" class="form-control" />
          </div>
          <i
            v-b-tooltip.top.noninteractive="$t('dynamicModels.selectorHelp')"
            class="fal fa-question-circle ml-1"
          />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.mandatoryField") }}
        </span>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.maxDepth") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="d-flex align-items-center">
          <div class="input-group mr-1">
            <input
              v-model="config.maxDepth"
              type="number"
              min="1"
              class="form-control"
            />
          </div>
          <i
            v-b-tooltip.top.noninteractive="$t('dynamicModels.maxDepthHelp')"
            class="fal fa-question-circle ml-1"
          />
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.entryQuantity") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div class="d-flex align-items-center">
          <div class="input-group mr-1">
            <input
              v-model="config.entryQuantity"
              type="number"
              min="1"
              class="form-control"
            />
          </div>
          <i
            v-b-tooltip.top.noninteractive="
              $t('dynamicModels.entryQuantityHelp')
            "
            class="fal fa-question-circle ml-1"
          />
        </div>
        <span class="form-text text-muted">
          {{ $t("dynamicModels.mandatoryField") }}
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mt-3">{{ $t("dynamicModels.forceArrayOn") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-9">
        <div
          v-for="(item, i) in config.forceArrayOn"
          :key="i"
          class="d-flex align-items-center mb-1"
        >
          <div class="input-group mr-1">
            <input
              v-model="config.forceArrayOn[i]"
              type="text"
              class="form-control"
              @keydown.tab="onTab(i, $event)"
            />
          </div>
          <i
            class="fal fa-circle-minus icon-lg text-danger cursor-pointer"
            @click="removeField(i)"
          />
        </div>
        <div class="d-flex justify-space-between align-items-center">
          <button class="btn btn-primary" @click="addField">
            <i class="fal fa-plus" />{{ $t("dynamicModels.forceArrayOnAdd") }}
          </button>
          <i
            v-b-tooltip.top.noninteractive="
              $t('dynamicModels.forceArrayOnHelp')
            "
            class="fal fa-question-circle ml-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encodingOptions } from "@/components/Tools/encodingOptions";

export default {
  props: ["initialConfig"],
  data() {
    return {
      config: {
        selector: "",
        maxDepth: "",
        forceArrayOn: [],
        entryQuantity: "",
        encoding: "UTF-8",
        forceEncoding: false
      },
      showPreview: true,
      encodingOptions: encodingOptions
    };
  },
  mounted() {
    this.mapInitialConfig();
  },
  methods: {
    mapInitialConfig() {
      this.config.type = this.initialConfig.type;
      this.config.selector = this.initialConfig.selector;
      this.config.maxDepth = this.initialConfig.maxDepth ?? 3;
      this.config.forceArrayOn = this.initialConfig.forceArrayOn ?? [];
      this.config.encoding = this.initialConfig.encoding;
      this.config.forceEncoding = this.initialConfig.forceEncoding;
      if (
        typeof this.config.forceArrayOn === "string" &&
        this.config.forceArrayOn.length
      )
        this.config.forceArrayOn = JSON.parse(this.config.forceArrayOn);
      this.config.entryQuantity = this.initialConfig.entryQuantity ?? 10;
    },
    fetchConfig() {
      let config = JSON.parse(JSON.stringify(this.config));
      config.forceArrayOn = JSON.stringify(config.forceArrayOn);
      if (config.forceArrayOn.length < 5) delete config.forceArrayOn;
      return config;
    },
    fetchRawConfig() {
      return this.config;
    },
    addField() {
      this.config.forceArrayOn.push("");
    },
    removeField(key) {
      this.config.forceArrayOn.splice(key, 1);
    },
    onTab(key, e) {
      if (key === this.config.forceArrayOn.length - 1 && !e.shiftKey)
        this.addField();
    }
  }
};
</script>
