<template>
  <div>
    <b-tabs v-model="data.mode">
      <b-tab title="Datei" class="my-6" @click="onTabChanged(0)">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">
              {{ $t("dynamicModels.fileSelection") }}:
            </h5>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">
            <div class="mt-2">{{ $t("dynamicModels.file") }}</div>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-9">
            <b-form-file
              ref="file-input"
              v-model="data.file"
              :placeholder="$t('dynamicModels.filePlaceholder')"
              :accept="acceptFileTypesCaseInsensitive"
              :drop-placeholder="$t('dynamicModels.dropPlaceholder')"
              @input="onFileInput"
            >
              <template #placeholder>
                {{
                  data.file
                    ? data.file.name
                    : $t("dynamicModels.filePlaceholder")
                }}
              </template>
            </b-form-file>
            <span class="text-muted">
              {{ $t("dynamicModels.acceptedFileTypes") }}: {{ acceptFileTypes }}
            </span>
          </div>
        </div>
      </b-tab>

      <b-tab title="Text" class="my-6" @click="onTabChanged(1)">
        <div class="row">
          <h5 class="font-weight-bold ml-4">
            {{ $t("dynamicModels.textInput") }}:
          </h5>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <div class="row">
              <b-form-textarea
                v-model="data.text"
                rows="10"
                class="form-control"
                placeholder="Text"
                @input="onTextChanged"
              />
              <span class="text-muted">
                {{ $t("dynamicModels.acceptedFormats") }}: {{ acceptFormats }}
              </span>
            </div>
            <div class="row">
              <span v-if="invalid" class="error--text">
                {{ $t("dynamicModels.typeInvalid", { type: fileTypeString }) }}
              </span>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div
      v-if="data.fileType !== null"
      class="separator separator-dashed my-5"
    ></div>
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div class="col-xl-3 col-lg-3"></div>
          <div v-if="data.fileType !== null" class="col-md-9 col-lg-8 col-xl-9">
            <h5 class="font-weight-bold">
              {{
                $t("dynamicModels.analyzeSettings") +
                (data.mode === 1 ? " (" + fileTypeString + ")" : "")
              }}:
            </h5>
          </div>
        </div>
      </div>
      <div v-if="data.mode === 1" class="col-4">
        <h5 class="font-weight-bold ml-15">{{ $t("dynamicModels.type") }}:</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CSV
          v-if="data.fileType === 0"
          ref="step-analyze-config-0"
          :initial-config="data.config"
        />
        <XML
          v-else-if="data.fileType === 1"
          ref="step-analyze-config-1"
          :initial-config="data.config"
        />
        <JSONConfig
          v-else-if="data.fileType === 2"
          ref="step-analyze-config-2"
          :initial-config="data.config"
        />
      </div>
      <div v-if="data.mode === 1" class="col-4">
        <div class="form-group ml-15">
          <div @click="suppressTypeRecognition">
            <b-form-radio
              v-model="selectedType"
              value="0"
              @change="onTypeChanged(0)"
              >{{ $t("dynamicModels.csv") }}</b-form-radio
            >
          </div>
          <div @click="suppressTypeRecognition">
            <b-form-radio
              v-model="selectedType"
              value="1"
              @change="onTypeChanged(1)"
              >{{ $t("dynamicModels.xml") }}</b-form-radio
            >
          </div>
          <div @click="suppressTypeRecognition">
            <b-form-radio
              v-model="selectedType"
              value="2"
              @change="onTypeChanged(2)"
              >{{ $t("dynamicModels.json") }}</b-form-radio
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSV from "@/components/Projects/DynamicModels/DynamicModel/Configs/CSV";
import XML from "@/components/Projects/DynamicModels/DynamicModel/Configs/XML";
import JSONConfig from "@/components/Projects/DynamicModels/DynamicModel/Configs/JSON";
import fastXmlParser from "fast-xml-parser";
import { isJson } from "@/components/Tools/helperFunctions";

export default {
  components: {
    CSV,
    XML,
    JSONConfig
  },
  props: ["data"],
  data() {
    return {
      validFileTypes: ["csv", "xml", "json"],
      selectedType: null,
      invalid: false,
      fileTypeSelectedManually: false
    };
  },
  computed: {
    acceptFileTypes: function () {
      let types = this.validFileTypes;
      let acceptString = "";
      types.forEach(type => {
        let string = "." + type + ", ";
        acceptString = acceptString.concat(string);
      });
      acceptString = acceptString.slice(0, -2);
      return acceptString;
    },
    acceptFormats: function () {
      let types = this.validFileTypes;
      let acceptString = "";
      types.forEach(type => {
        let string = type + ", ";
        acceptString = acceptString.concat(string);
      });
      acceptString = acceptString.slice(0, -2);
      return acceptString;
    },
    acceptFileTypesCaseInsensitive: function () {
      let accepted = this.acceptFileTypes;
      return accepted + ", " + accepted.toUpperCase();
    },
    fileTypeString: function () {
      if (
        this.data.fileType === null ||
        this.data.fileType > this.validFileTypes.length
      )
        return "";
      return this.validFileTypes[this.data.fileType].replace(".", "");
    }
  },
  watch: {
    selectedType: function (newValue) {
      this.data.fileType = newValue;
    }
  },
  created() {
    this.onTypeChanged(this.data.fileType);
  },
  methods: {
    suppressTypeRecognition() {
      this.fileTypeSelectedManually = true;
    },
    onTabChanged(mode) {
      this.data.mode = mode;
      if (this.data.mode === 1) {
        this.data.fileType = this.selectedType;
      } else {
        this.onFileInput(this.data.file);
      }
    },
    onFileInput(file) {
      this.data.fileType = null;

      if (file == null) return;

      let filename = file.name;
      let extension = filename.split(".").pop().toLowerCase();
      let extensionMode = null;
      switch (extension) {
        case "csv":
          extensionMode = 0;
          break;
        case "xml":
          extensionMode = 1;
          break;
        case "json":
          extensionMode = 2;
          break;
      }
      this.data.fileType = extensionMode;
    },
    getConfig() {
      return this.$refs[
        "step-analyze-config-" + this.data.fileType
      ].fetchConfig();
    },
    onTextChanged() {
      // Suppress type recognition when type was selected manually
      if (this.fileTypeSelectedManually) {
        return;
      }
      this.data.fileType = null;
      this.selectedType = null;
      if (this.data.text.length === 0) return;
      if (isJson(this.data.text)) {
        this.data.fileType = 2;
      } else if (this.isXML(this.data.text)) {
        this.data.fileType = 1;
      } else {
        this.data.fileType = 0;
      }
      this.onTypeChanged(this.data.fileType);
    },
    onTypeChanged(value) {
      this.invalid = false;
      this.selectedType = value;
      this.data.type = value;

      if (this.data.type === 1 && !this.isXML(this.data.text)) {
        this.invalid = true;
      } else if (this.data.type === 2 && !isJson(this.data.text)) {
        this.invalid = true;
      }
    },
    isXML(item) {
      return fastXmlParser.validate(item) === true;
    }
  }
};
</script>
