<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("dynamicModels.preview", { rows: previewData.length }) }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <vue-json-pretty
          highlight-mouseover-node
          :data="previewData"
          selectable-type="single"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty
  },
  props: ["items", "previewLines"],
  computed: {
    previewData: function () {
      return this.items.length > this.previewLines
        ? this.items.slice(0, this.previewLines)
        : this.items;
    }
  }
};
</script>
