<template>
  <div>
    <div v-if="showDataStructureOptions" class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("dataStructures.dataStructureBasis") }}:
        </h5>
      </div>
    </div>

    <div
      v-if="showDataStructureOptions"
      class="form-group row align-items-center"
    >
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.dataStructureBasisSelect") }}
      </label>
      <div class="col-9">
        <v-select
          v-model="dataStructureOption"
          class="form-control"
          :items="dataStructureOptions"
          item-text="label"
          item-value="value"
          :menu-props="{ offsetY: true }"
          @input="onDataStructureOptionChanged"
        />
      </div>
    </div>

    <div
      v-if="[1, 3].includes(dataStructureOption)"
      class="form-group row align-items-center"
    >
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.dataStructureBasisSelectOriginal") }}
      </label>
      <div class="col-9">
        <div class="input-group">
          <v-select
            v-model="dataStructureTemplate"
            class="form-control"
            :items="dataStructuresTemplates"
            :item-text="structure => getDataStructureItemText(structure)"
            item-value="id"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dataStructures.placeholderSelect')"
            :loading="dataStructureTemplateLoading"
            @change="setBaseData"
          />
          <div v-if="$v.existingStructureId.$error" class="input-group-append">
            <span class="input-group-text border-0">
              <i class="text-danger icon-lg fal fa-circle-exclamation" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-dashed my-5" />

    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("dataStructures.baseData") }}:</h5>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-3 col-form-label">
        <div class="mt-3">{{ $t("dataStructures.label") }}</div>
      </label>
      <div class="col-9">
        <b-form-input
          v-model="dataStructure.label"
          class="form-control"
          :class="{
            'is-invalid': $v.dataStructure.label.$error,
            'is-valid': $v.dataStructure.label.required
          }"
          trim
          @input="touch()"
        />
      </div>
    </div>

    <div class="form-group row align-items-center" v-if="appVersion < 2">
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.version") }}
      </label>
      <div class="col-9">
        <b-form-input
          v-model="dataStructure.version"
          class="form-control"
          :class="{
            'is-invalid': $v.dataStructure.version.$error,
            'is-valid': $v.dataStructure.version.required
          }"
          type="number"
          trim
          :disabled="dataStructureOption === 1"
          @input="touch"
        />
      </div>
    </div>

    <div
      v-if="configuration.area !== 0"
      class="form-group row align-items-center"
    >
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.published") }}
      </label>
      <div class="col-9 d-flex align-center">
        <span class="switch switch-sm mr-1">
          <label>
            <input v-model="dataStructure.is_published" type="checkbox" />
            <span></span>
          </label>
        </span>
        <i
          v-b-tooltip.top.noninteractive="$t('dataStructures.publishedTooltip')"
          class="fal fa-question-circle"
        />
      </div>
    </div>

    <div class="form-group row align-items-center" v-if="appVersion < 2">
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.isLocked") }}
      </label>
      <div class="col-9 d-flex align-center">
        <span class="switch switch-sm mr-1">
          <label>
            <input v-model="dataStructure.is_locked" type="checkbox" />
            <span></span>
          </label>
        </span>
        <i
          v-b-tooltip.top.noninteractive="$t('dataStructures.isLockedTooltip')"
          class="fal fa-question-circle"
        />
      </div>
    </div>
    <div class="form-group row align-items-center" v-if="appVersion < 2">
      <label class="col-3 col-form-label">
        {{ $t("dataStructures.dataVersioning") }}
      </label>
      <div class="col-9 d-flex align-center">
        <span class="switch switch-sm mr-1">
          <label>
            <input v-model="dataStructure.useDataVersioning" type="checkbox" />
            <span></span>
          </label>
        </span>
        <i
          v-b-tooltip.top.noninteractive="
            $t('dataStructures.dataVersioningTooltip')
          "
          class="fal fa-question-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { integer, minLength, required } from "vuelidate/lib/validators";

let DataStructureService;

export default {
  components: {},
  props: {
    configuration: Object,
    showDataStructureOptions: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    dataStructure: {
      label: {
        required,
        minLength: minLength(3)
      },
      version: { required, integer }
    },
    existingStructureId: {
      condition: function () {
        return (
          (this.dataStructureOption === 1 && this.dataStructureTemplate) ||
          this.dataStructureOption !== 1
        );
      }
    }
  },
  data() {
    return {
      dataStructureOption: 0,
      dataStructureOptions: [],
      dataStructureTemplate: "",
      dataStructureTemplateLoading: false,
      dataStructuresTemplates: [],
      dataStructure: {
        version: 1,
        is_published: false,
        is_locked: false,
        useDataVersioning: false,
        is_system_structure: false
      }
    };
  },
  watch: {
    dataStructureOption() {
      delete this.dataStructure["parent_id"];
      delete this.dataStructure["fields"];
      delete this.dataStructure["primary"];
    }
  },
  mounted() {
    DataStructureService = this.configuration.dataStructureService;
    this.getDataStructureOptions();
    this.getDataStructureTemplates();
    if (this.configuration.area !== 3) {
      this.getDataStructures();
    }
  },
  methods: {
    getDataStructureItemText(dataStructure) {
      let text = `v.${dataStructure.version}`;

      if (dataStructure.is_system_structure) {
        text += " (" + this.$t("dataStructures.globalTemplate") + ")";
      }
      return text;
    },
    stringToSnakeCase(string) {
      return string
        .replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join("_");
    },
    getDataStructureOptions() {
      this.dataStructureOptions.push({
        label: this.$t("dataStructures.dataStructureBasisNew"),
        value: 0
      });

      if (this.configuration.area !== 3 && this.appVersion < 2) {
        this.dataStructureOptions.push({
          label: this.$t("dataStructures.dataStructureBasisTemplate"),
          value: 1
        });
      }

      this.dataStructureOptions.push({
        label: this.$t("dataStructures.dataStructureBasisExisting"),
        value: 3
      });
      this.dataStructureOptions.push({
        label: this.$t("dataStructures.dataStructureBasisFile"),
        value: 2
      });
    },
    getDataStructureTemplates() {
      if (this.appVersion >= 2) {
        return;
      }
      this.dataStructureTemplateLoading = true;
      this.dataStructuresTemplates = [];
      let params = { page: 1, size: 99 };
      DataStructureService.getTemplates(params, this.configuration.area === 3)
        .then(response => {
          this.dataStructuresTemplates = response.data.data;
          this.dataStructureTemplateLoading = false;
        })
        .catch(error => {
          this.dataStructureTemplateLoading = false;
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
        });
    },
    getDataStructures() {
      this.dataStructureTemplateLoading = true;
      this.dataStructuresTemplates = [];
      let params = { size: 99 };
      DataStructureService.getAll(params)
        .then(response => {
          this.dataStructuresTemplates = response.data.data;
          this.dataStructureTemplateLoading = false;
        })
        .catch(error => {
          this.dataStructureTemplateLoading = false;
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
        });
    },
    async setBaseData() {
      let selectedStructure = this.dataStructuresTemplates.find(
        item => item.id === this.dataStructureTemplate
      );
      if (!selectedStructure) return;

      if (selectedStructure.fields === undefined) {
        this.dataStructureTemplateLoading = true;
        await DataStructureService.getTemplate(selectedStructure.id)
          .then(response => {
            selectedStructure.fields = response.data.data.fields;
          })
          .finally(() => {
            this.dataStructureTemplateLoading = false;
          });
      }

      this.dataStructure.version = selectedStructure.version;
      if (this.dataStructureOption !== 1) {
        this.dataStructure.version++;
      }
      this.dataStructure.primary = selectedStructure.primary;
      this.dataStructure.fields = selectedStructure.fields;
      this.dataStructure.queries = selectedStructure.queries;
      this.dataStructure.is_system_structure =
        selectedStructure.is_system_structure;
      this.dataStructure.parent_id = selectedStructure.id;
      this.touch(true);
    },
    onDataStructureOptionChanged() {
      this.touch(false);
      this.dataStructureTemplate = "";
      this.dataStructure.is_system_structure = false;

      if (this.dataStructureOption === 1) {
        this.getDataStructureTemplates();
      }
      if (this.dataStructureOption === 3) {
        this.getDataStructures();
      }
    },
    touch(validate = true) {
      if (validate) {
        this.$v.$touch();
      }

      this.$emit(
        "data-structure-changed",
        this.dataStructureOption,
        !this.$v.$invalid,
        this.dataStructure
      );
    }
  }
};
</script>
